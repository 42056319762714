import { Box, ChakraProvider } from '@chakra-ui/react';
import './App.css';
import Header from './Header';
import About from './pages/About';
import Contact from './pages/Contact';
import Game from './pages/Game';
import { theme } from './themes';

function App() {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh">
        <Header />
        <Box bg="brand.800" minH="80vh">
          <Game scrollToSection={scrollToSection} />
        </Box>
        <About scrollToSection={scrollToSection} />
        <Contact scrollToSection={scrollToSection} />
      </Box>
    </ChakraProvider >
  );
}

export default App;
