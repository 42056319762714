export const contactCopy = {
    header: 'Contact Us',
    paragraphs: [
        { paragraph: 'At Happy Place Productions, we\'re always working on new and exciting board games that we can\'t wait to share with our fans.', mt: 4 },
        { paragraph: 'If you\'re interested in learning more about our upcoming releases or staying in touch with us, we\'d love to hear from you!', mt: 3 },
        { paragraph: 'You can reach us by email at ', mt: 3 },
        { paragraph: 'Let us know what you\'re interested in, whether it\'s information about upcoming releases, feedback on our existing games, or just to say hello. We\'ll get back to you as soon as possible.', mt: 3 },
        { paragraph: 'Thank you for your interest in Happy Place Productions and for being a part of our community of board game enthusiasts.', mt: 3 },
        { paragraph: 'We can\'t wait to hear from you!', mt: 3 },
    ]
};