import { extendTheme } from "@chakra-ui/react"

const getTheme = (theme: 'regular' | 'christmas' | 'halloween') => {

    switch (theme) {
        case 'regular':
            return {
                100: "#0c0c0c",
                200: "#AAA15F",
                300: "#3F988B",
                400: "#4C3C6B",
                500: "#A0F3ED",
                600: "#241E4E",
                700: "#15122e",
                800: "#100e24",
            }
        case 'christmas':
            return {
                100: "#500707",
                200: "#1D5609",
                300: "#F3EFED",
                400: "#F40B0B",
                500: "#678968",
                600: "#CB8D6E",
                700: "#BB0E0A",
                800: '#DED5AF',
            }

        case 'halloween':
            return {
                100: "#D7A82A",
                200: "#1C352C",
                300: "#985E5B",
                400: "#553C5A",
                500: "#D7A82A",
                600: "#8A7BA0",
                700: "#E08959",
                800: "#400040",
            }

        default:
            return {
                100: "#000000",
                200: "#AAA15F",
                300: "#3F988B",
                400: "#4C3C6B",
                500: "#A0F3ED",
            }
    }
}

export const theme = extendTheme({
    colors: {
        brand:
            getTheme('regular')
    },
})


