import React from 'react';
import { Box, Text, Button, Center, Heading, Stack } from '@chakra-ui/react'

type GameProps = {
    scrollToSection: (id: 'contact' | 'about' | 'game') => void
}

const Game = ({ scrollToSection }: GameProps) => {
    return (
        <Stack direction={{ base: "column", lg: "row" }} pt={10} pb={10}
            justifyContent="center" display="flex"
            alignItems="center" id="game">
            <Box w={{ base: "90vw", lg: "45vw" }} className="game-copy">
                <Heading as="h1"
                    textAlign="center" color="white"
                    fontSize={{ base: "45px", md: "55px", lg: "65px" }}
                    fontWeight={200}>
                    The Witching Hour
                </Heading>
                <Text mt={4} textAlign="justify" color="white" fontSize="18px">
                    Get ready for a thrilling game of strategy and deceit with “The Witching Hour”.
                    Take on the roles of witches, witch hunters, and acolytes as you compete to either complete
                    "The Binding Ritual" to elect your next leader, the Supreme or sabotage it.
                </Text>
                <Text as="p" color="white" textAlign="justify" mt={3} fontSize="18px">
                    This game will keep you on the edge of your seat and it is perfect for both seasoned board
                    game enthusiasts and newcomers to the hobby.
                </Text>
                <Text as="p" color="white" textAlign="justify" mt={3} fontSize="18px">
                    So gather your coven and get ready to outwit your opponents in "The Witching Hour".
                </Text>
                <Text as="p" color="white" textAlign="justify" mt={3} fontSize="18px">
                    The fate of the coven is in your hands, but can you figure out who&apos;s friend or foe?
                </Text>
                <Center color="black">
                    <Button
                        _hover={{ backgroundColor: "none" }}
                        color="white" m={5} p={7} fontSize="34px"
                        className="game-button"
                        onClick={() => scrollToSection('contact')}>
                        FIND OUT
                    </Button>
                </Center>
            </Box>
            <Box w={{ base: "75vw", lg: "40vw" }} h={{ base: "50vh" }} className="game-gif" />
        </Stack>
    );
}

export default Game;
