import React from 'react';
import { Box, Text, Heading, Stack } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { contactCopy } from '../copies/contact';

type ContactProps = { scrollToSection: (id: 'contact' | 'about' | 'game') => void }

const Contact = ({ scrollToSection }: ContactProps) => {
    const { header, paragraphs } = contactCopy;
    return (
        <Stack direction={{ base: "column-reverse", lg: "row" }}
            minH="90vh" bg="#100c32"
            justifyContent="center" display="flex"
            alignItems="center" className="contact-wrapper">

            <Box w={{ base: "90vw", lg: "45vw" }} mr={{ base: 0, lg: 10 }} mt={{ base: 5, lg: 0 }} mb={{ base: 10, lg: 0 }} className="contact-gif" />
            <Box w={{ base: "90vw", lg: "45vw" }} pt={{ base: 10, lg: 0 }}
                className="contact-copy" id="contact">
                <Heading as="h1" textAlign="center" color="white">
                    {header}
                </Heading>
                {paragraphs.map(({ paragraph, mt }, index: number) => (
                    <Text color="white" mt={mt} key={index}>
                        {paragraph}
                        {index === 2 &&
                            < Link
                                to='#'
                                className='email-link'
                                onClick={(e) => {
                                    window.location.href = "mailto:contact@happyplaceproductions.co.uk";
                                    e.preventDefault();
                                }}
                            >
                                contact@happyplaceproductions.co.uk
                            </Link>
                        }
                    </Text>
                ))}
            </Box>
        </Stack >
    );
}

export default Contact;
