export const aboutCopy = {
    headerPart1: 'Welcome to',
    headerPart2: 'Happy Place Productions',
    paragraphs: [
        { paragraph: 'We are a dynamic board game studio dedicated to creating fun and engaging games that bring people together.', pb: 4 },
        { paragraph: 'At Happy Place Productions, we believe that board games are more than just entertainment; they\'re a way to connect with friends and family, to challenge ourselves, and to create lasting memories. That\'s why we\'re committed to designing and producing games that are not only exciting and innovative, but also promote social interaction and bring people closer together.', pb: 4 },
        { paragraph: 'We strive to create unique and immersive experiences that appeal to players of all ages and backgrounds. Whether you\'re a seasoned board gamer or a newcomer to the hobby, Happy Place Productions has something for everyone.', pb: 4 },
        { paragraph: 'We are excited to be part of the vibrant and growing board game community, and we can\'t wait to share our games with the world.', pb: 4 },
        { paragraph: 'So come join us and find your happy place with Happy Place Productions. Let\'s play!', pb: 0 },
    ]
}