import { Heading, HStack, Image, VStack } from '@chakra-ui/react';
import './App.css';
import company_no_text from './assets/images/logo_no_text.png';


const Header = () => {
  return (
    <HStack
      w="100%"
      bgColor="brand.400"
      justifyContent={{ base: "space-between", md: "center" }}
      alignItems="center"
      pb={1} pt={1}
      pl={{ base: 4, md: 0 }}
    >
      <VStack className='heading-copy' justifyItems="center">
        <Heading as="h6">Happy Place</Heading>
        <Heading as="h6" className='company-name-part-2'>Productions</Heading>
      </VStack>
      <Image src={company_no_text} ml={0} alt="company logo" width="85px" />
    </HStack >

  );
}

export default Header;
