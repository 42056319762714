import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/react'
import { aboutCopy } from '../copies/about';

type AboutProps = { scrollToSection: (id: 'contact' | 'about' | 'game') => void }

const About = ({ scrollToSection }: AboutProps) => {
    const { headerPart1, headerPart2, paragraphs } = aboutCopy;
    return (
        <Box className="about-wrapper" id="about" minH="95vh"
            justifyContent="center" display="flex" alignItems="center">
            <Box w="60vw" className="about-copy">
                <Heading as="h1" textAlign="center" color="white" >
                    {headerPart1}
                </Heading>
                <Heading as="h1" textAlign="center" color="white" pb={5}>
                    {headerPart2}
                </Heading>
                {paragraphs.map(({ paragraph, pb }, index) =>
                    <Text as="p" color="white" fontSize="20px" pb={pb} textAlign="justify" key={index}>
                        {paragraph}
                    </Text>
                )}
            </Box>
        </Box>
    );
}

export default About;
